








import GameProviderSettingsForm from '@/components/game-provider/GameProviderSettingsForm.vue';

export default {
  name: 'SuperAdminGameProviderSettings',
  components: {
    GameProviderSettingsForm
  },
  computed: {
    accountId(): number | null {
      return +this.$route.params.id || null;
    }
  },
  methods: {
    deletedGameProviderSettingsFormHandler(): void {
      this.$router.push({ name: 'providers-accounts' });
    }
  }
};
